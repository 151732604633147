import imageAsb from "../assets/images/customers/asb.jpg";
import imageAyudaEnAccion from "../assets/images/customers/ayuda-en-accion.jpg";
import imageCojiMargatia from "../assets/images/customers/cojines-margarita.jpg";
import imageCoopAlemana from "../assets/images/customers/cooperacion-alemana.jpg";
import imageDavivienda from "../assets/images/customers/davivienda.jpg";
import imageEnter from "../assets/images/customers/enter-504.jpg";
import imageFundemas from "../assets/images/customers/fundemas.jpg";
import imageFunpres from "../assets/images/customers/funpres.jpg";
import imageGroup from "../assets/images/customers/group.jpg";
import imageIglesia from "../assets/images/customers/iglesia-de-dios.jpg";
import imageInstituto from "../assets/images/customers/INSAMI.jpg";
import imageMovilidad from "../assets/images/customers/movilidad-humana.jpg";
import imageMyBookResume from "../assets/images/customers/my-book-resumen.jpg";
import imageOCDIH from "../assets/images/customers/OCDIH.jpg";
import imagePlanInt from "../assets/images/customers/plan-internacional.jpg";
import imagePNUD from "../assets/images/customers/pnud.jpg";
import imageSvnet from "../assets/images/customers/svnet.jpg";
import imageTresMundos from "../assets/images/customers/tres-mundos.jpg";
import imageWinrock from "../assets/images/customers/winrock.jpg";

export const customers = [
    {
        id: 1,
        name: "ASB",
        image: imageAsb,
    },
    {
        id: 2,
        name: "Ayuda en Acción",
        image: imageAyudaEnAccion,
    },
    {
        id: 3,
        name: "Cojines Margarita",
        image: imageCojiMargatia,
    },
    {
        id: 4,
        name: "Cooperación Alemana",
        image: imageCoopAlemana,
    },
    {
        id: 5,
        name: "Davivienda",
        image: imageDavivienda,
    },
    {
        id: 6,
        name: "Enter 504",
        image: imageEnter,
    },
    {
        id: 7,
        name: "Fundemas",
        image: imageFundemas,
    },
    {
        id: 8,
        name: "Funpres",
        image: imageFunpres,
    },
    {
        id: 9,
        name: "Group",
        image: imageGroup,
    },
    {
        id: 10,
        name: "Iglesia de Dios",
        image: imageIglesia,
    },
    {
        id: 11,
        name: "INSAMI",
        image: imageInstituto,
    },
    {
        id: 12,
        name: "Movilidad Humana",
        image: imageMovilidad,
    },
    {
        id: 13,
        name: "My Book Resume",
        image: imageMyBookResume,
    },
    {
        id: 14,
        name: "OCDIH",
        image: imageOCDIH,
    },
    {
        id: 15,
        name: "Plan Internacional",
        image: imagePlanInt,
    },
    {
        id: 16,
        name: "PNUD",
        image: imagePNUD,
    },
    {
        id: 17,
        name: "Svnet",
        image: imageSvnet,
    },
    {
        id: 18,
        name: "Tres Mundos",
        image: imageTresMundos,
    },
    {
        id: 19,
        name: "Winrock",
        image: imageWinrock,
    },
];
