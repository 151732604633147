import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { technologies } from '../../constants/web-services';

const OurTech = () => {
    const { t } = useTranslation();

    return (
        <Row className="bg-service-portfolio">
            <Col className="p-none bg-shadow-service text-white">
                <Container className="py-5">
                    <div className="text-center mb-4" data-aos="fade-up">
                        <h2 className="fw-bold">
                            { t('our-tech') }
                        </h2>
                    </div>
                    <div id="web-plataforms">
                        <Row>
                            { technologies.map((tech, index) => {
                                return (
                                    <Col key={ index } xl={2} lg={2} md={6} sm={6} xs={6} >
                                        <Card className="card-tech m-3 bg-transparent plataform h-100" data-aos="fade-up">
                                            <Card.Body className="tech-body rounded">
                                                <div className="d-flex justify-content-center">
                                                    <div className="tech-icon">
                                                        { tech.icon }
                                                    </div>
                                                </div>
                                                <div className="text-white text-center">
                                                    <h6 className="fw-bold mt-4">
                                                        { tech.name }
                                                    </h6>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                );
                            }) }
                        </Row>
                    </div>
                </Container>
            </Col>
        </Row>
    );
};

export default OurTech;

