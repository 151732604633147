import Zoom from 'react-medium-image-zoom';

import 'react-medium-image-zoom/dist/styles.css';

const ImagePortfolio = ({ data, service }) => {
    let img = require(`../../assets/images/portfolio/${service}/${data.image}`).default;

    return (
        <div
        className={ `item-container border-color-${service}` } 
        >
            <Zoom
                overlayBgColorStart={ 'rgba(0, 0, 0, 0)' }
                overlayBgColorEnd={ 'rgba(0, 0, 0, 0.75)' }
                zoomMargin={ 30 }
            >
                <img 
                    src={ img }
                    className="w-100 h-100 img-project-portfolio"
                    alt={ data.name }
                />
            </Zoom>
        </div>
    );
}

export default ImagePortfolio;
