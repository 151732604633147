// import Media from '../../assets/videos/video.mp4';
import Media from '../../assets/videos/home.gif';

const Video = () => {
    return (
        // <video 
        //     id="home-video" 
        //     src={ Media } 
        //     autoPlay 
        //     loop 
        //     muted 
        //     playsInline 
        //     controls={ false }>
        //     <source src={ Media } type='video/mp4' />
        // </video>
        <img 
            id="home-video"
            src={ Media }
            alt=""
        />
    );
}

export default Video;

