import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'; 

import { Container, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import ContactForm from '../content/ContactForm';

import Footer from '../../components/theme/Footer';

// Translate
import translate from '../../data/contacts.json';

import '../../assets/css/Contact.css';

const Contact = () => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState(null);

    const translateContact = useCallback(() => {
        let dataContact = [];

        switch (cookies.get('i18next')) {
            case 'es':
            default:
                for (let i = 0; i < translate.length; i++) {
                    dataContact.push({
                        key: translate[i].key,
                        title: translate[i].es.title,
                        value: translate[i].value,
                        link: translate[i].link
                    });
                }
                break;
                
            case 'en':
                for (let i = 0; i < translate.length; i++) {
                    dataContact.push({
                        key: translate[i].key,
                        title: translate[i].en.title,
                        value: translate[i].value,
                        link: translate[i].link
                    });
                }
                break;
        }
        
        setItems(dataContact);
        setIsLoading(true);
    }, [setItems]);

    useEffect(() => {
        if (isLoading === false) {
            translateContact();

            document.title = `${t('contact')} | TICONGLE Agency`;
        }
    }, [isLoading, translateContact, t]);

    return (
        <div id="contact" className="h-100 p-none">
            <Row className="bg-img-contact h-100-vh">
                <Col className="p-none bg-shadow text-white">
                    <Container className="h-100">
                        <div className="h-100 py-5 d-flex align-items-center justify-content-start">
                            <h1 className="portfolio-title" data-aos="fade-right">
                                { t('contact') }
                            </h1>
                        </div>
                    </Container>
                </Col>
            </Row>
            <Row className="bg-img-contact-2 h-100-vh d-none d-xl-block d-lg-block d-md-block d-sm-none d-xs-none">
                <Col className="p-none bg-shadow text-white h-100">
                    <Container className="h-100">
                        <div className="d-flex align-items-start h-100 py-5 mx-3 w-100">
                            <Row className="h-100 w-100">
                                <Col xl={ 12 } lg={ 12 } md={ 12 } sm={ 12 } xs={ 12 } className="h-100" >
                                    <div className="d-flex justify-content-start flex-wrap h-100 w-100">
                                        <div className="w-100" data-aos="fade-right">   
                                            <div 
                                                className={ `top-bar-subtitle-section bg-warning` }
                                            ></div>
                                            <h4 className="text-white text-uppercase">{ t('contact-us') }</h4>
                                        </div>
                                        <div className="d-flex align-items-center flex-wrap h-100 w-100">   
                                            <div className="h-100 w-100">
                                                <Row className="m-none h-100">
                                                    <Col xl={ 6 } lg={ 6 } md={ 12 } sm={ 12 } xs={ 12 } className="p-none">
                                                        <div className="h-100">
                                                            <div className="h-100 d-flex align-items-center justify-content-start">
                                                                <div className="text-start">
                                                                    <h1 className="about-title" data-aos="fade-right"
                                                                        dangerouslySetInnerHTML={{ __html: t('contact-title') }}
                                                                    >
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xl={ 6 } lg={ 6 } md={ 12 } sm={ 12 } xs={ 12 } className="p-none">
                                                        <div className="h-100">
                                                            { items && (
                                                                <div className="h-100 d-flex align-items-start justify-content-center flex-column">
                                                                    {items.map((item) => {
                                                                        return (
                                                                            <div className="text-start w-100" key={ item.key } data-aos="fade-up">
                                                                                <div className="d-flex flex-column contact-key mb-2">
                                                                                    <span className="contact-key-name mb-1">{ item.title }</span>
                                                                                    <a className="contact-key-link mt-1" href={ item.link } target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={faArrowRight} className='icon-color' />{ item.value }</a>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            ) }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </Col>
            </Row>
            <Row className="bg-img-contact-2 d-block d-xl-none d-lg-none d-md-none d-sm-block d-xs-block">
                <Col className="p-none bg-shadow text-white h-100">
                    <Container className="h-100">
                        <div id="Home" className="d-flex align-items-start h-100 py-5 mx-3 w-100">
                            <Row className="h-100 w-100">
                                <Col xl={ 12 } lg={ 12 } md={ 12 } sm={ 12 } xs={ 12 } className="h-100" >
                                    <div className="d-flex justify-content-start flex-wrap h-100 w-100">
                                        <div className="w-100 my-2" data-aos="fade-right">   
                                            <div 
                                                className={ `top-bar-subtitle-section bg-warning` }
                                            ></div>
                                            <h4 className="text-white text-uppercase">{ t('contact-us') }</h4>
                                        </div>
                                        <div className="d-flex align-items-center flex-wrap h-100 w-100">   
                                            <div className="h-100 w-100">
                                                <Row className="m-none h-100">
                                                    <Col sm={ 12 } xs={ 12 } className="p-none">
                                                        <div className="h-100 mb-4">
                                                            <div className="h-100 d-flex align-items-center justify-content-start">
                                                                <div className="text-start">
                                                                    <h1 className="about-title" data-aos="fade-right"
                                                                        dangerouslySetInnerHTML={{ __html: t('contact-title') }}
                                                                    >
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col sm={ 12 } xs={ 12 } className="p-none">
                                                        <div className="h-100 my-3">
                                                            { items && (
                                                                <div className="h-100 d-flex align-items-start justify-content-center flex-column">
                                                                    {items.map((item) => {
                                                                        return (
                                                                            <div className="text-start w-100" key={ item.key } data-aos="fade-up">
                                                                                <div className="d-flex flex-column contact-key mb-2">
                                                                                    <span className="contact-key-name mb-1">{ item.title }</span>
                                                                                    <a className="contact-key-link mt-1" href={ item.link } target="_blank" rel="noopener noreferrer" >{ item.value }</a>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            ) }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </Col>
            </Row>
            <Row className="bg-purple-dark-ticongle">
                <Col className="p-none bg-shadow-service text-white">
                    <Container className="">
                        <div id="Home" className="d-flex align-items-start py-5 mx-3 w-100">
                            <Row className=" w-100">
                                <Col xl={ 12 } lg={ 12 } md={ 12 } sm={ 12 } xs={ 12 } className="h-100" >
                                    <div className="d-flex justify-content-start flex-wrap h-100 w-100">
                                        <div className="w-100" data-aos="fade-right">   
                                            <div 
                                                className={ `top-bar-subtitle-section bg-info` }
                                            ></div>
                                            <h4 className="text-white text-uppercase">{ t('write-us') }</h4>
                                        </div>
                                        <div className="d-flex align-items-center flex-wrap h-100 w-100 my-5">   
                                            <div className="h-100 w-100">
                                                <Row className="m-none h-100 w-100">
                                                    <Col xl={ 6 } lg={ 6 } md={ 12 } sm={ 12 } xs={ 12 } className="p-none">
                                                        <div className="h-100">
                                                            <div className="h-100 d-flex align-items-center justify-content-start">
                                                                <div className="text-start">
                                                                    <h1 className="form-about-title" data-aos="fade-right"
                                                                        dangerouslySetInnerHTML={{ __html: t('form-title') }}
                                                                    >
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xl={ 6 } lg={ 6 } md={ 12 } sm={ 12 } xs={ 12 } className="p-none">
                                                        <div className="h-100">
                                                            <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                                                                <ContactForm />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </Col>
            </Row>
            <Row>
                <Col className="p-none">
                    <Footer />
                </Col>
            </Row>
        </div>
    );
}

export default withRouter(Contact);
