import { useState, useEffect, useCallback } from 'react';
import i18next from 'i18next'; 
import { useTranslation } from 'react-i18next'; 
import cookies from 'js-cookie'; 

import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Brand from '../../assets/images/agency-white.png';

// Translate
import translate from '../../data/services.json';

import '../../assets/css/Navigation.css';

const IconToggle = styled.button`
    background: transparent;
    border: none;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    margin: 0 0 0 0;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    &::before,
    &::after {
        content: "";
        background-color: #fff;
        height: 3px;
        width: 1.5rem;
        position: absolute;
        transition: all 0.3s ease;
    }

    &::before {
        top: ${(props) => (props.clicked ? "1.5" : "1rem")};
        transform: ${(props) => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
    }

    &::after {
        top: ${(props) => (props.clicked ? "1.2" : "1.5rem")};
        transform: ${(props) => (props.clicked ? "rotate(-135deg)" : "rotate(0)")};
    }
`;

const Navigation = () => {
    const { t } = useTranslation(); 
 
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState(null);

    const [click, setClick] = useState(false);
    const handleClick = () => {
        if (!click) {
            document.getElementById('body').classList.add('overflow-hidden');
        } else {
            document.getElementById('body').classList.remove('overflow-hidden');
        }

        setClick(!click);
    };

    const [active, setActive] = useState(false);
    const activeItem = () => setActive(!active);

    const translateSection = useCallback(() => {
        let dataServices = [];

        switch (cookies.get('i18next')) {
            case 'es':
            default:
                for (let i = 0; i < translate.length; i++) {
                    dataServices.push({
                        key: translate[i].key,
                        info: translate[i].es
                    });
                }
                break;
                
            case 'en':
                for (let i = 0; i < translate.length; i++) {
                    dataServices.push({
                        key: translate[i].key,
                        info: translate[i].en
                    });
                }
                break;
        }

        setItems(dataServices);
        setIsLoading(true);
    }, [setIsLoading, setItems]);

    const changeLanguage = (lang) => {
        i18next.changeLanguage(lang);

        window.location.reload();
    };

    useEffect(() => {
        if (isLoading === false) {
            translateSection();
        }
    }, [isLoading, translateSection]);

    return (
        <div id="Navigation" className="fixed-top">
            <div className="py-3 bg-purple-ticongle ">
                <Row className="m-none">
                    <Col>
                        <div className="pt-2 h-100 w-100">
                            <a href="/#">
                                <img 
                                    src={ Brand }
                                    className="w-100"
                                    alt="Ticongle Agency"
                                />
                            </a>
                        </div>
                    </Col>
                    <Col>
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <span className="language-responsive">
                                <span className="translate-link" onClick={ () => changeLanguage('es') } >ES</span> 
                            </span>
                            &nbsp;
                            <span className="language-responsive">
                                <span className="translate-link" onClick={ () => changeLanguage('en') } >EN</span> 
                            </span>
                        </div>
                    </Col>
                    <Col>
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <IconToggle clicked={ click } onClick={() => handleClick()} />
                        </div>
                    </Col>
                </Row>
            </div>
            <div className={ `nav-content ${ click ? "w-100" : "w-0" }` }>
                <div className="d-flex flex-column sidebar-padding w-100 h-100">
                    <Container>
                        <nav className="nav-mobile text-start">
                            <div className="navbar-items">
                                <div className="item">
                                    <Link clicked={ click } onClick={() => handleClick()} to="/" className="link text-uppercase">{ t('home') }</Link>
                                </div>
                                <div className="item">
                                    <Link clicked={ click } onClick={() => handleClick()} to="/about" className="link text-uppercase">{ t('about') }</Link>
                                </div>
                                { items && (
                                    <div className="item">
                                        <div
                                            className="text-uppercase link cursor-pointer" 
                                            clicked={ active } 
                                            onClick={() => activeItem()}
                                        >{ t('services') }</div>
                                        <div 
                                            className={ `m-none submenu-mobile ${ active ? "h-auto-submenu-mobile" : "h-0-submenu-mobile" }` }
                                        >
                                        {/* <div className="m-none submenu-sidebar"> */}
                                            {items.map((item) => {
                                                return (
                                                    <div key={ item.key } className="sub-item">
                                                        <Link 
                                                            to={ `/services/${item.key}` } 
                                                            className="link-subitem"
                                                            clicked={ click }
                                                            onClick={() => handleClick()}
                                                        >{ item.info.item }</Link>
                                                    </div>  
                                                );
                                            })}
                                        </div>
                                    </div>
                                ) }
                                <div className="item">
                                    <Link clicked={ click } onClick={() => handleClick()} to="/blog" className="link text-uppercase">{ t('blog-title') }</Link>
                                </div>
                                <div className="item">
                                    <Link clicked={ click } onClick={() => handleClick()} to="/team" className="link text-uppercase">{ t('our-team') }</Link>
                                </div>
                                <div className="item">
                                    <Link clicked={ click } onClick={() => handleClick()} to="/reviews" className="link text-uppercase">{ t('reviews-title') }</Link>
                                </div>
                                <div className="item">
                                    <Link clicked={ click } onClick={() => handleClick()} to="/contact" className="link text-uppercase">{ t('contact') }</Link>
                                </div>
                            </div>
                        </nav>
                    </Container>
                </div>
            </div>
        </div>
    );
}

export default Navigation;
