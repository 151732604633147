import { useState, useEffect, useCallback } from 'react';
import i18next from 'i18next'; 
import { useTranslation } from 'react-i18next'; 
import cookies from 'js-cookie'; 

import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Images
import Brand from '../../assets/images/agency-brand-white.png';

// Translate
import translate from '../../data/services.json';
import Social from '../../data/social-media.json';

import '../../assets/css/Sidebar.css';

const IconToggle = styled.button`
    background: transparent;
    border: none;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    margin: 0.5rem 0 0 0.5rem;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    &::before,
    &::after {
        content: "";
        background-color: #fff;
        height: 3px;
        width: 1.5rem;
        position: absolute;
        transition: all 0.3s ease;
    }

    &::before {
        top: ${(props) => (props.clicked ? "1.5" : "1rem")};
        transform: ${(props) => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
    }

    &::after {
        top: ${(props) => (props.clicked ? "1.2" : "1.5rem")};
        transform: ${(props) => (props.clicked ? "rotate(-135deg)" : "rotate(0)")};
    }
`;

const Sidebar = () => {
    const { t } = useTranslation(); 

    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState(null);

    const [click, setClick] = useState(false);
    const handleClick = () => {
        if (!click) {
            document.getElementById('body').classList.add('body-scroll');
        } else {
            document.getElementById('body').classList.remove('body-scroll');
        }

        setClick(!click)
    };

    const [active, setActive] = useState(false);
    const activeItem = () => setActive(!active);

    const translateSection = useCallback(() => {
        let dataServices = [];

        switch (cookies.get('i18next')) {
            case 'es':
            default:
                for (let i = 0; i < translate.length; i++) {
                    dataServices.push({
                        key: translate[i].key,
                        info: translate[i].es
                    });
                }
                break;
                
            case 'en':
                for (let i = 0; i < translate.length; i++) {
                    dataServices.push({
                        key: translate[i].key,
                        info: translate[i].en
                    });
                }
                break;
        }

        setItems(dataServices);
        setIsLoading(true);
    }, [setIsLoading, setItems]);

    const changeLanguage = (lang) => {
        i18next.changeLanguage(lang);

        window.location.reload();
    };

    useEffect(() => {
        if (isLoading === false) {
            translateSection();
        }
    }, [isLoading, translateSection]);

    return (
        <div className="h-100 w-100">
            <Row className="w-100 m-none">
                <Col xl={ 12 } lg={ 12 } className="border-red p-none">
                    <nav id="Sidebar" className="d-none d-lg-block h-100 sticky-top bg-purple-ticongle p-none m-none">
                        <div className="h-100">
                            <Row className="h-100 w-100 m-revert">
                                {/* Brand */}
                                <Col className="h-25-vh p-none" xl={ 12 } lg={ 12 }>
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <a href="/" className="h-100 px-3 pt-3" >
                                            <img 
                                                src={ Brand }
                                                className="ticongle-agency-brand"
                                                alt="Ticongle Agency"
                                            />
                                        </a>
                                    </div>
                                </Col>
                                {/* internationalization */}
                                <Col className="h-25-vh p-none" xl={ 12 } lg={ 12 }>
                                    <div className="h-100 py-3">
                                        <div className="d-flex justify-content-center align-items-center h-100">
                                                <ul className="language">
                                                    <li> 
                                                        <span className="translate-link" onClick={ () => changeLanguage('es') } >ES</span> 
                                                    </li> 
                                                    <li> 
                                                        <span className="translate-link" onClick={ () => changeLanguage('en') } >EN</span> 
                                                    </li>
                                                </ul>
                                        </div>
                                    </div>
                                </Col>
                                {/* Dropdown */}
                                <Col className="h-25-vh p-none" xl={ 12 } lg={ 12 }>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <IconToggle clicked={ click } onClick={() => handleClick()} />
                                    </div>
                                </Col>
                                {/* Social networks */}
                                <Col className="h-25-vh p-none" xl={ 12 } lg={ 12 }>
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <ul className="social-text">
                                            { Social.map((item, index) => {
                                                if (item.active) {
                                                    return (
                                                        <li key={ index }>
                                                            <a href={ item.link } target="_blank" rel="noopener noreferrer">{ item['short-name'] }</a>
                                                        </li>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            }) }
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </nav>
                </Col>
                <Col className="p-none">
                    <div className={ 
                        `sidebar-menu h-100 bg-sidebar-right ${ click ? "w-100" : "w-0" }`
                    }>
                        <div className="d-flex align-items-center h-100 pl-5-rem">
                            <div className="sidebar-items">
                                <div className="item">
                                    <Link clicked={ click } onClick={() => handleClick()} to="/" className="link text-uppercase">{ t('home') }</Link>
                                </div>
                                <div className="item">
                                    <Link clicked={ click } onClick={() => handleClick()} to="/about" className="link text-uppercase">{ t('about') }</Link>
                                </div>
                                { items && (
                                    <div className="item">
                                        <div
                                            className="text-uppercase link cursor-pointer" 
                                            clicked={ active } 
                                            onClick={() => activeItem()}
                                        >{ t('services') }</div>
                                        <div 
                                            className={ `m-none submenu-sidebar ${ active ? "h-auto-submenu" : "h-0-submenu" }` }
                                        >
                                        {/* <div className="m-none submenu-sidebar"> */}
                                            {items.map((item) => {
                                                return (
                                                    <div key={ item.key } className="sub-item">
                                                        <Link 
                                                            to={ `/services/${item.key}` } 
                                                            className="link-subitem"
                                                            clicked={ click }
                                                            onClick={() => handleClick()}
                                                        >{ item.info.title }</Link>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                ) }
                                <div className="item">
                                    <Link clicked={ click } onClick={() => handleClick()} to="/blog" className="link text-uppercase">{ t('blog-title') }</Link>
                                </div>
                                <div className="item">
                                    <Link clicked={ click } onClick={() => handleClick()} to="/team" className="link text-uppercase">{ t('our-team') }</Link>
                                </div>
                                <div className="item">
                                    <Link clicked={ click } onClick={() => handleClick()} to="/reviews" className="link text-uppercase">{ t('reviews-title') }</Link>
                                </div>
                                <div className="item">
                                    <Link clicked={ click } onClick={() => handleClick()} to="/contact" className="link text-uppercase">{ t('contact') }</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default Sidebar;

