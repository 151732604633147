import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTranslation } from 'react-i18next';

import brand from '../../assets/images/agency-white.png';
import Social from '../../data/social-media.json';

import 'font-awesome/css/font-awesome.min.css';

import '../../assets/css/Footer.css';

const Footer = () => {

    const { t } = useTranslation();

    const getCurrentYear = () => {
        let date = new Date();
        let year = date.getFullYear();

        return year;
    }

    return (
        <div id="footer" className="w-100 h-100 bg-footer text-white">
            <div className="py-4">
                <Container>
                    <Row className="w-100 m-none">
                        <Col xl={ 4 } lg={ 4 } md={ 6 } sm={ 12 } xs={ 12 }>
                            <div className="w-100">
                                <img
                                    src={ brand }
                                    className="w-100 p-4"
                                    alt=""
                                />
                            </div>
                        </Col>
                        <Col xl={ 4 } lg={ 4 } md={ 6 } sm={ 12 } xs={ 12 }>
                            <div className="d-flex justify-content-center align-items-center h-100">
                                <p className="slogan-footer">{ t('slogan') }</p>
                            </div>
                        </Col>
                        <Col xl={ 4 } lg={ 4 } md={ 12 } sm={ 12 } xs={ 12 }>
                            <div className="h-100 py-3 d-flex justify-content-evenly" >
                                { Social.map((item, index) => {
                                    if (item.active) {
                                        return (
                                            <div key={ index } className="h-100 d-flex justify-content-center align-items-center">
                                                <a className="link-icon" href={ item.link } target="_blank" rel="noopener noreferrer">
                                                    <FontAwesomeIcon icon={[ 'fab', item.icon ]} size="2x" />
                                                </a>
                                            </div>
                                        );
                                    } else {
                                        return null;
                                    }
                                    
                                }) }
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="mt-4 d-flex justify-content-center align-items-center">
                    <Row className="m-none">
                        <Col xl={ 12 } lg={ 12 } md={ 12 } sm={ 12 } xs={ 12 }>
                            <h6 className="text-center copyright">&copy; { getCurrentYear() } TICONGLE Agency</h6>
                        </Col>
                        <Col xl={ 12 } lg={ 12 } md={ 12 } sm={ 12 } xs={ 12 }>
                            <h6 className="text-center copyright">Hecho en El Salvador por <a href="https://conexion.sv"  target="_blank" rel="noopener noreferrer" className="link-copyright-author">CONEXION</a></h6>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default Footer;
