import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

const ImageTeam = ({ link, photo }) => {
    let image = require(`../../assets/images/${photo}`).default;

    return (
        <a href={ link } target="_blank" rel="noopener noreferrer" className="img-hover-zoom img-team">
            <Card.Img variant="top" src={ image } />
            <div className="overlay">
                <FontAwesomeIcon className="text-white" icon={ faLinkedin } size="4x" />
            </div>
        </a>
    );
}

export default ImageTeam;
