import { Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faFacebook, faInstagram, faLinkedin, faWhatsapp, faTwitter, faTiktok } from '@fortawesome/free-brands-svg-icons';

import i18next from 'i18next'; 
import { useTranslation, initReactI18next } from 'react-i18next'; 
import LanguageDetector from 'i18next-browser-languagedetector'; 
import HttpApi from 'i18next-http-backend';

// Components
import Sidebar from './components/theme/Sidebar';
import Navigation from './components/theme/Navigation';
import ScrollTop from './components/utils/ScrollTop';
import WhatsappBtn from './components/content/WhatsappBtn';

// Pages
import Home from './components/pages/Home';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import Blog from './components/pages/Blog';
import Entry from './components/pages/Entry';
import Reviews from './components/pages/Reviews';
import Team from './components/pages/Team';
import Portfolio from './components/pages/Portfolio';
import NotFound from './components/errors/404NotFound';

// CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/App.css';

// Translate
import translate from './data/site-texts.json';

library.add(fab, faFacebook, faInstagram, faLinkedin, faWhatsapp, faTwitter, faTiktok);

const resources = {
	es: {
		translation: translate.es.translation
	},
	en: {
		translation: translate.en.translation
	}
};

i18next 
.use(initReactI18next) 
.use(LanguageDetector) 
.use(HttpApi) 
.init({ 
    supportedLngs: ['es', 'en'], 
    fallbackLng: 'es', 
    debug: false, 
    detection: { 
        order: ['path', 'cookie', 'htmlTag'], 
        caches: ['cookie'] 
    }, 
    resources
});

const App = () => {
	const { t } = useTranslation();

	return (
		<div className="App">
			<Container fluid className="h-100">
				<Row className="h-100"> 
					<Col className="p-none h-100 fixed-top d-none d-lg-block" xl={ 1 } lg={ 1 } md={ 1 } sm={ 1 } xs={ 1 }>
						<Sidebar />
					</Col>
					<Col className="d-block d-lg-none p-none" md={ 12 } sm={ 12 } xs={ 12 }>
						<Navigation />
					</Col>
					<Col xl={ 1 } lg={ 1 }></Col>
					<Col xl={ 11 } lg={ 11 } md={ 12 } sm={ 12 } xs={ 12 } >
						<Fragment>
							<ScrollTop />
							<Switch>
								<Route exact path="/" component={ Home } />
								<Route path="/services/:service" component={ Portfolio } />
								<Route path="/about" component={ About } />
								<Route path="/contact" component={ Contact } />
								<Route path="/team" component={ Team } />
								<Route path="/blog" component={ Blog } />
								<Route path="/entry/:slug" component={ Entry } />
								<Route path="/reviews" component={ Reviews } /> 
								<Route path="/not-found" component={ NotFound } />
								<Route component={ NotFound } />
							</Switch>
							<WhatsappBtn/>
						</Fragment>
						
						<CookieConsent 
							contentClasses={ 'text-center' }
							location="bottom"
							buttonText={ t('cookie-button') }
							buttonClasses={ 'btn-cookie' }
							buttonWrapperClasses={ 'cookie-message' }
							cookieName="AgencySite"
  							style={{ background: "#0e0e0ebf" }}
  							buttonStyle={{ color: "#222", fontSize: "15px" }}
  							expires={ 15 }
						>{ t('cookie-message') }</CookieConsent>
					</Col>
				</Row>
			</Container>
			
		</div>
	);
}

export default App;
