import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useEffect, useCallback, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';

import Footer from '../../components/theme/Footer';

const Entry = () => {
    const { slug } = useParams();
    const history = useHistory();
    const [entryData, setEntryData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const loadData = useCallback(() => {
        setIsLoading(false);

        let uri = `${process.env.REACT_APP_CMS_URL}/wp-json/wp/v2/posts?slug=${slug}&_embed&timestamp=${new Date().getTime()}`;
        
        axios.get(uri)
        .then((data) => {
           let info = data.data;

            if (info.length > 0) {
                setEntryData(info[0]);
            } else {
                history.push('/not-found');
            }
        })
        .catch((error) => {
            console.log(error);
            
            history.push('/not-found');
        });

        if (entryData !== null) {
            document.title = `${entryData.title.rendered} | TICONGLE Agency`;
        }

        setTimeout(() => {
            setIsLoading(true);
        }, 5000);
    }, [entryData, history, slug]);

    const formatDate = (date) => {
        let format = new Date(date);
        let lang = Cookies.get('i18next');
        
        let day = format.getDate();
        let mes = format.toLocaleString(lang, { month: 'long' });
        let year = format.getFullYear();

        let completeDate = mes + " " + day + ", " + year;

        return completeDate.charAt(0).toUpperCase() + completeDate.slice(1);;
    };

    const tiktokScriptCallback = () => {
        let tiktokScript = document.createElement('script');

        tiktokScript.async = true;
        tiktokScript.src = 'https://www.tiktok.com/embed.js';
        
        tiktokScript.onload = () => {
            window.tiktokScriptLoaded = true;
            console.log("TikTok script loaded!");
        };

        document.body.appendChild(tiktokScript);
        document.body.removeChild(tiktokScript);
    };
    
    useEffect(() => {
        if (isLoading === false) {
            loadData();
        }

        if (entryData !== null) {
            tiktokScriptCallback();
        }   
    }, [isLoading, loadData]);

    return (
        <div id="Entry" className="h-100 p-none">
            <Row className="bg-service-portfolio min-vh-100">
                <Col className="p-none bg-shadow-service text-white">
                    <Container className="h-100 py-5">
                        <article className="pt-5">
                            <>
                                { (isLoading === true) && (
                                    <>
                                        { (entryData !== null) && (
                                            <>
                                                <header className="mb-4">
                                                    <h1 className="fw-bolder mb-1 text-center entry-title">
                                                        { entryData.title.rendered }
                                                    </h1>
                                                    <div className="fst-italic mb-2 text-white">
                                                        { formatDate(entryData.date) }
                                                        </div>
                                                    { entryData._embedded['wp:term'][0].map((item, index) => {
                                                        return (
                                                            <span key={ index } className="category-entry badge">
                                                                { item.name }
                                                            </span>
                                                        );
                                                    }) }
                                                </header>
                                                { (entryData._embedded['wp:featuredmedia'] !== undefined) && (
                                                    <figure className="mb-4">
                                                        <img
                                                            src={ entryData._embedded['wp:featuredmedia'][0].source_url }
                                                            className="img-fluid img-entry"
                                                            alt={ entryData.title.rendered }
                                                        />
                                                    </figure>
                                                ) }
                                                <section>
                                                    <div className="entry-content" dangerouslySetInnerHTML={{ __html: entryData.content.rendered }} />
                                                </section>
                                            </>
                                        ) }
                                    </>
                                ) }
                            </>
                            <>
                                { (isLoading === false) && (
                                    <>
                                        <header className="mb-4">
                                            <div className="d-flex justify-content-center">
                                                <h1 className="fw-bolder mb-3 text-center entry-title-load">
                                                    <br />
                                                </h1>
                                            </div>
                                            <div className="fst-italic mb-2 text-white entry-date">
                                                <br />
                                            </div>
                                            <span className="badge category-entry-load">
                                                <br />
                                            </span>
                                            <span className="badge category-entry-load">
                                                <br />
                                            </span>
                                            <span className="badge category-entry-load">
                                                <br />
                                            </span>
                                        </header>
                                        <figure className="mb-4">
                                            <div className="w-100 img-entry-load"></div>
                                        </figure>
                                        <section>
                                            <div className="entry-content-load" />
                                        </section>
                                    </>
                                ) }
                            </>
                        </article>
                    </Container>
                </Col>
            </Row>
            <Row>
                <Col className="p-none">
                    <Footer />
                </Col>
            </Row>
        </div>
    );
};

export default Entry;
