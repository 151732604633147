import axios from 'axios';
import React, { useEffect, useCallback, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import Footer from '../../components/theme/Footer';
// import Paginate from '../content/Paginate';

import Thumbnail from '../../assets/images/blog-thumbnail.png';
import Preload from '../../assets/svg/loading-post.svg';

import '../../assets/css/Blog.css';

const Blog = () => {
    const { t } = useTranslation();
    const [blogPosts, setBlogPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const loadData = useCallback(() => {
        setIsLoading(false);

        let uri = `${process.env.REACT_APP_CMS_URL}/wp-json/wp/v2/posts?_embed&timestamp=${new Date().getTime()}`;
        
        axios.get(uri)
        .then((data) => {
            setBlogPosts(data.data);
        })
        .catch((error) => {
            console.log(error);
            setBlogPosts([]);
        });

        document.title = `${t('blog-title')} | TICONGLE Agency`;
        
        setTimeout(() => {
            setIsLoading(true);
        }, 5000);
    }, [t]);

    useEffect(() => {
        if (isLoading === false) {
            loadData();
        }
    }, [isLoading, loadData]);

    return (
        <>
            <div id="blog" className="h-100 p-none">
                <Row className="bg-img-about h-100-vh">
                    <Col className="p-none bg-shadow text-white">
                        <Container className="h-100">
                            <div className="h-100 py-5 d-flex align-items-center justify-content-start">
                                <h1 className="portfolio-title" data-aos="fade-right">
                                    { t('blog-title') }
                                </h1>
                            </div>
                        </Container>
                    </Col>
                </Row>
                <Row className="bg-service-portfolio" >
                    <Col className="p-none bg-shadow-service text-white">
                        <Container className="h-100 py-5">
                            <Row>
                                <Col xl={ 12 } lg={ 12 } md={ 12 } sm={ 12 } xs={ 12 }>
                                    <h2 className="fw-bold" data-aos="fade-right">{ t('blog-text') }</h2>
                                    <br/>
                                    <p className="text-justify" data-aos="fade-right">{ t('blog-description') }</p>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                { (isLoading === false) && (
                                    <div className="d-flex justify-content-center my-3">
                                        <img
                                            src={ Preload }
                                            alt=""
                                        />
                                    </div>
                                ) }
                                { (isLoading === true) && (
                                    <>
                                        <>
                                            { (blogPosts.length >= 1) && (
                                                blogPosts.map((item, index) => {
                                                    return (
                                                        <Col key={ index } xl={ 4 } lg={ 4 } md={ 6 } sm={ 12 } xs={ 12 } >
                                                            <LazyLoad>
                                                                <Card className="blog-content-section my-3 w-100" data-aos="fade-up" >
                                                                    { (item._embedded['wp:featuredmedia'] !== undefined) && (
                                                                        <>
                                                                            <Card.Img 
                                                                                src={ item._embedded['wp:featuredmedia'][0].source_url } 
                                                                                className="card-image-blog"
                                                                                variant="top" 
                                                                            />
                                                                        </>
                                                                    ) }
                                                                    { (item._embedded['wp:featuredmedia'] === undefined) && (
                                                                        <>
                                                                            <Card.Img 
                                                                                variant="top" 
                                                                                src={ Thumbnail } 
                                                                            />
                                                                        </>
                                                                    ) }
                                                                    <div className="card-date">
                                                                        { new Date(item.date).getFullYear() }-{ new Date(item.date).getMonth() }-{ new Date(item.date).getDay() }
                                                                    </div>
                                                                    <Card.Body>
                                                                        <Card.Title className="pb-1 text-center">
                                                                            { item.title.rendered.length > 90 ?
                                                                                `${item.title.rendered.substring(0, 90)}...` : item.title.rendered
                                                                            }
                                                                        </Card.Title>
                                                                    </Card.Body>
                                                                    <Card.Footer className="text-center pb-3">
                                                                        <Link 
                                                                            className="btn btn-read-more"
                                                                            to={ `/entry/${item.slug}` }
                                                                        >{ t('blog-read-more') }</Link>
                                                                    </Card.Footer>
                                                                </Card>
                                                            </LazyLoad>
                                                        </Col>
                                                    );
                                                })
                                            ) }
                                        </>
                                        <>
                                            { (blogPosts.length < 1) && (
                                                <div className="d-flex justify-content-center my-3">
                                                    <div className="not-post-blog">
                                                        <h4>No se encontraron publicaciones disponibles.</h4>
                                                    </div>
                                                </div>
                                            ) }
                                        </>
                                    </>
                                ) }
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row>
                    <Col className="p-none">
                        <Footer />
                    </Col>
                </Row>
            </div>
            
        </>
    );
};

export default withRouter(Blog);

