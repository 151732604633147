import React from 'react';
import ReactDOM from 'react-dom';
import { Circle } from 'react-preloaders2';
import { HashRouter as Router } from 'react-router-dom';

import App from './App';

import './assets/css/index.css';

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<main className="h-100">
				<App />
			</main>
		</Router>
		<Circle 
			background={ '#222' } 
			color={ '#EE9118' }
		/>
	</React.StrictMode>,
	document.getElementById('root')
);



