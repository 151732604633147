import React from 'react';
import { Col, Container, Row, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { customers } from '../../constants/customer';

const OurClients = () => {
    const { t } = useTranslation();

    return (
        <Row className="bg-service-portfolio">
            <Col className="p-none bg-shadow-service text-white">
                <Container className="py-5">
                    <div className="text-center mb-4" data-aos="fade-up">
                        <h2 className="fw-bold">
                            { t('our-customers') }
                        </h2>
                    </div>
                    <div id="web-plataforms">
                        <Row>
                            { customers.map((item, index) => {
                                return (
                                    <Col key={ index } xl={3} lg={3} md={6} sm={6} xs={6} >
                                        <Card className="card-tech bg-transparent plataform h-100" data-aos="fade-up">
                                            <Card.Body className="tech-body rounded">
                                                <div className="d-flex justify-content-center">
                                                    <div className="tech-icon">
                                                        <img
                                                            src={ item.image }
                                                            alt={ item.name }
                                                            className="img-fluid rounded"
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="text-white text-center">
                                                    <h6 className="fw-bold mt-4">
                                                        { tech.name }
                                                    </h6>
                                                </div> */}
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                );
                            }) }
                        </Row>
                    </div>
                </Container>
            </Col>
        </Row>
    );
};

export default OurClients;

