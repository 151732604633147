import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import MainButton from './MainButton';
// import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function QuestionButton({ text, link, className, openInNewTab, showCotizaButton }) {
    const target = openInNewTab ? "_blank" : "_self";

    const { t } = useTranslation();
    // const location = useLocation();

    // let showCotizaButton = false;
    // if (location.pathname === "/") {
    //     showCotizaButton = true;
    // }

    return (
        <div>
            <p className='question'>
                { t('question-boot-business') }
            </p>
            <div className='btns-container'>
                <div className={`btn-main ${className}`}>
                    <FontAwesomeIcon icon={faAngleRight} />
                    <a
                        href={"https://wa.me/+50376029613"}
                        target={target}
                        rel={openInNewTab ? "noopener noreferrer" : ""}
                        className={className}
                    >
                        {text}
                    </a>
                </div>

                {showCotizaButton && (
                    <MainButton
                        text={ t('quote') }
                        link={"https://wa.me/+50376029613"}
                        className={className}
                        openInNewTab={openInNewTab}
                    />

                )}
            </div>
        </div>
    );
}

export default QuestionButton;


