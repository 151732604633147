import React from 'react';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
import axios from 'axios';
import { Form, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import ReCAPTCHA from "react-google-recaptcha";

const recaptchaRef = React.createRef();

const ContactForm = () => {
    const { t } = useTranslation();

    const pattern = /^(([^<>().,;:\s@”]+(\.[^<>().,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/i;
    let captchaValue = null;

    const { register, handleSubmit, formState: { errors } } = useForm();
    
    const onSubmit = (data) => {
        if (captchaValue !== null) {
            let uri = 'https://agency.ticongle.com/api/email.php';
            
            axios.post(uri, {
                name: data.Name,
                email: data.Email,
                subject: data.Subject,
                comment: data.Comment
            })
            .then((response) => {
                // console.log(response);
                if (response.data.type === 'error') {
                    Swal.fire({
                        title: response.data.message,
                        icon: response.data.type,
                        confirmButtonText: 'Ok'
                    });
                }

                if (response.data.type === 'success') {
                    Swal.fire({
                        title: response.data.message,
                        icon: response.data.type,
                        confirmButtonText: 'Ok'
                    });

                    document.getElementById("contact-form").reset();
                }
            })
            .catch((error) => {
                console.log(error);
            });

            recaptchaRef.current.reset();
        } else {
            let message_captcha = null;

            switch (cookies.get('i18next')) {
                case 'es':
                default:
                    message_captcha = "Debe completar el reCAPTCHA";
                    break;
        
                case 'en':
                    message_captcha = "You must complete the reCAPTCHA";
                    break;
            }

            Swal.fire({
                title: message_captcha,
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
    };

    const onChange = (value) => {
        captchaValue = value;
    }
    
    return (
        <div className="text-start w-100" data-aos="fade-up">
            <Form id="contact-form" className="contact-form" onSubmit={ handleSubmit(onSubmit) } >
                <Form.Group className="mb-3" controlId="name">
                    <Form.Control className="form-control-style" type="text" placeholder={ t('form-name') } 
                        {...register("Name", { 
                            required: t('form-name-required'), 
                            maxLength: 80
                        })} 
                        autoComplete="new-password"
                    />
                    <ErrorMessage
                        errors={ errors }
                        name="Name"
                        render={({ message }) => <span className="invalid-feedback d-block">{ message }</span>}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="email">
                    <Form.Control className="form-control-style" type="email" placeholder={ t('form-email') } 
                        {...register("Email", {
                            required: t('form-email-required'), 
                            pattern: {
                                value: pattern,
                                message: t('form-email-pattern')
                            }
                        })} 
                        autoComplete="new-password"
                    />
                    <ErrorMessage
                        errors={ errors }
                        name="Email"
                        render={({ message }) => <span className="invalid-feedback d-block">{ message }</span>}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="subject">
                    <Form.Control className="form-control-style" type="text" placeholder={ t('form-subject') } {...register("Subject", {required: t('form-subject-required') })} autoComplete="new-password" />
                    <ErrorMessage
                        errors={ errors }
                        name="Subject"
                        render={({ message }) => <span className="invalid-feedback d-block">{ message }</span>}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="comment">
                    <Form.Control className="textarea-resize form-control-style-textarea" as="textarea" rows={ 3 } placeholder={ t('form-comment') } {...register("Comment", {
                        required: t('form-comment-required')
                    })} autoComplete="new-password" />
                    <ErrorMessage
                        errors={ errors }
                        name="Comment"
                        render={({ message }) => <span className="invalid-feedback d-block">{ message }</span>}
                    />
                </Form.Group>
                <div className="mb-3">
                    <ReCAPTCHA
                        sitekey="6LfwCGkcAAAAAISD7l6JGnFhoIw4PPjw28LNA5vC"
                        onChange={ onChange }
                        ref={ recaptchaRef }
                        hl={ cookies.get('i18next') }
                    />
                </div>
                <Button type="submit">{ t('form-send') }</Button>
            </Form>
        </div>
    );
}

export default ContactForm;

