import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
import useWindowDimensions from '../hooks/useWindowDimensions';

import { Container, Row, Col, Card } from 'react-bootstrap';
import Slider from "react-slick";
import { withRouter } from 'react-router-dom';
import TeamCertification from '../content/TeamCertification';

import ImageTeam from '../content/ImageTeam';

import Footer from '../../components/theme/Footer';

import Members from '../../data/team.json';

import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import '../../assets/css/Team.css';

const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 7000,
    cssEase: "linear",
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 435,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 375,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
                infinite: true,
                dots: false
            }
        }
    ]
};

const Team = () => {
    const { t } = useTranslation();
    const { width } = useWindowDimensions();

    const [isLoading, setIsLoading] = useState(false);
    const [teamMembers, setTeamMembers] = useState(null);

    const translateTeam = useCallback(() => {
        let dataMembers = [];

        switch (cookies.get('i18next')) {
            case 'es':
            default:
                for (let i = 0; i < Members.length; i++) {
                    dataMembers.push({
                        name: Members[i].name,
                        lastname: Members[i].lastname,
                        job: Members[i].job.es,
                        photo: Members[i].photo,
                        linkedin: Members[i].linkedin,
                        status: Members[i].status
                    })
                }
                break;
                
            case 'en':
                for (let i = 0; i < Members.length; i++) {
                    console.log(Members[i]);

                    dataMembers.push({
                        name: Members[i].name,
                        lastname: Members[i].lastname,
                        job: Members[i].job.en,
                        photo: Members[i].photo,
                        linkedin: Members[i].linkedin,
                        status: Members[i].status
                    })
                }
                break;
        }
        
        document.title = `${t('our-team')} | TICONGLE Agency`;

        setTeamMembers(dataMembers);
        setIsLoading(true);
    }, [setIsLoading, t]);

    useEffect(() => {
        if (isLoading === false) {
            translateTeam();
        }
    }, [isLoading, translateTeam]);

    return (
        <div id="Team" className="h-100 p-none">
            <Row className="bg-img-team h-100-vh">
                <Col className="p-none bg-shadow text-white">
                    <Container className="h-100">
                        <div className="h-100 py-5 d-flex align-items-center justify-content-start">
                            <h1 className="portfolio-title" data-aos="fade-right">
                                { t('our-team') }
                            </h1>
                        </div>
                    </Container>
                </Col>
            </Row>
            <Row className="h-100-vh bg-service-portfolio" >
                <Col className="p-none bg-shadow-service text-white">
                    <Container className="h-100">
                        { teamMembers && (
                            <div className="h-100 d-flex align-items-center">
                                
                                { (width <= 375) && (
                                    <div className="w-100 container" data-aos="fade-up" id="mobile-team">
                                        <Slider {...settings} >
                                            { teamMembers.map((item, index) => {
                                                if (item.status === 1) {
                                                    return (
                                                        <div key={ index }>
                                                            <div className="mx-2">
                                                                <Card className="card-team">
                                                                    <ImageTeam
                                                                        link={ item.linkedin }
                                                                        photo={ item.photo }
                                                                    />
                                                                    <Card.Body>
                                                                        <Card.Title className="team-name text-center">{ item.name }&nbsp;{ item.lastname }</Card.Title>
                                                                        <Card.Text className="text-center">
                                                                            <small>{ item.job }</small>
                                                                        </Card.Text>
                                                                        {/* <div className="text-center">
                                                                            <a className="link-icon" href="/#" target="_blank" rel="noopener noreferrer">
                                                                                <FontAwesomeIcon icon={ faLinkedin } size="2x" />
                                                                            </a>
                                                                        </div> */}
                                                                    </Card.Body>
                                                                </Card>
                                                            </div>
                                                        </div>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            }) }
                                        </Slider>
                                    </div>
                                ) }
                                
                                { (width > 375) && (
                                    <Container className="w-100" data-aos="fade-up" id="desktop-team">
                                        <Slider className="container" {...settings} >
                                            { teamMembers.map((item, index) => {
                                                if (item.status === 1) {
                                                    return (
                                                        <div key={ index }>
                                                            <Container>
                                                                <Card className="card-team">
                                                                    <ImageTeam
                                                                        link={ item.linkedin }
                                                                        photo={ item.photo }
                                                                    />
                                                                    <Card.Body>
                                                                        <Card.Title className="team-name text-center">{ item.name }&nbsp;{ item.lastname }</Card.Title>
                                                                        <Card.Text className="text-center team-job">
                                                                            <small>{ item.job }</small>
                                                                        </Card.Text>
                                                                        {/* <div className="text-center">
                                                                            <a className="link-icon" href="/#" target="_blank" rel="noopener noreferrer">
                                                                                <FontAwesomeIcon icon={ faLinkedin } size="2x" />
                                                                            </a>
                                                                        </div> */}
                                                                    </Card.Body>
                                                                </Card>
                                                            </Container>
                                                        </div>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            }) }
                                        </Slider>
                                    </Container>
                                ) }

                            </div>
                        ) }
                    </Container>
                </Col>
            </Row>
            <TeamCertification />
            <Row>
                <Col className="p-none">
                    <Footer />
                </Col>
            </Row>
        </div>
    );
}

export default withRouter(Team);
