import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import AOS from 'aos';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link, withRouter } from 'react-router-dom';

import Footer from '../../components/theme/Footer';

import Agency from '../../assets/images/agency-white.png';
import Video from '../content/Video';

// Translate
import translate from '../../data/services.json';

import 'animate.css';
import 'aos/dist/aos.css';

import '../../assets/css/Home.css';
import MainButton from '../content/MainButton';
import QuestionButton from '../content/QuestionButton';
import OurClients from '../content/OurClients';


AOS.init();

const Home = () => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState(null);

    const translateHome = useCallback(() => {
        let dataServices = [];

        switch (cookies.get('i18next')) {
            case 'es':
            default:
                for (let i = 0; i < translate.length; i++) {
                    dataServices.push({
                        key: translate[i].key,
                        info: translate[i].es
                    });
                }
                break;

            case 'en':
                for (let i = 0; i < translate.length; i++) {
                    dataServices.push({
                        key: translate[i].key,
                        info: translate[i].en
                    });
                }
                break;
        }

        setItems(dataServices);
        setIsLoading(true);
    }, [setItems]);

    useEffect(() => {
        if (isLoading === false) {
            translateHome();

            document.title = `${t('home')} | TICONGLE Agency`;
        }
    }, [isLoading, translateHome, t]);

    return (
        <div id="Home" className="h-100 p-none">
            <Row className="h-100-vh" >
                <Col className="p-none home-video-shadow">
                    <Video />
                    <Container className="h-100">
                        <div id="Home" className="d-flex align-items-center h-100">
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12} >
                                    <div className="d-none d-lg-block">
                                        <div className="d-flex justify-content-flex-start">
                                            <img
                                                src={Agency}
                                                data-aos="fade-right"
                                                className="w-50 h-auto"
                                                alt="Ticongle Agency"
                                            />
                                        </div>
                                    </div>
                                    <div className="d-block d-lg-none">
                                        <div className="d-flex justify-content-flex-start">
                                            <img
                                                src={Agency}
                                                data-aos="fade-right"
                                                className="w-100 p-4 h-auto"
                                                alt="Ticongle Agency"
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12} >
                                    <div className="d-none d-lg-block d-xl-block pt-5">
                                        <p className="text-home w-75 text-justify">{t('text')}</p>
                                    </div>
                                    <div className="d-none d-lg-none d-md-block pt-5">
                                        <p className="text-home-md w-100 text-white text-justify">{t('text')}</p>
                                    </div>
                                    <div className="d-block d-lg-none d-md-none pt-5">
                                        <p className="text-home w-100 text-justify">{t('text')}</p>
                                    </div>
                                    <div className="d-flex gap-5">
                                        <MainButton
                                            text={ t('lets-talk') }
                                            className={"btn-home"}
                                            openInNewTab={true}
                                        />
                                        <MainButton
                                            text={ t('more-our') }
                                            className={"btn-home"}
                                            openInNewTab={true}
                                            url={ t('more-our-link') }
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </Col>
            </Row>
            {items && (
                items.map((item) => {
                    return (
                        <Row
                            className={`h-100-vh bg-img-${item.key} bg-config bg-config-${item.key}`}
                            key={item.key}
                        >
                            <Col
                                className={`bg-shadow-section p-none`}
                            >
                                <Container className="h-100">
                                    <div className="d-flex align-items-start h-100 py-5 mx-3 w-100">
                                        <Row className="h-100 w-100">
                                            <Col xl={12} lg={12} md={12} sm={12} xs={12} className="h-100" >
                                                <div className="d-flex justify-content-start flex-wrap h-100 w-100">
                                                    <div className="w-100" data-aos="fade-right">
                                                        <div
                                                            className={`top-bar-subtitle-section bg-${item.key}`}
                                                        ></div>
                                                        <h4 className="text-white text-uppercase">{t('our-services')}</h4>
                                                    </div>
                                                    <div className="d-flex align-items-center flex-wrap w-100">
                                                        <h1
                                                            className={`text-${item.key} title-section`}
                                                            data-aos="fade-right"
                                                        >{item.info.title}</h1>
                                                        <p
                                                            className="text-section-service text-white text-justify"
                                                            data-aos="fade-right"
                                                        >{item.info.text}</p>
                                                    </div>
                                                    <div className="w-100">
                                                        <Link
                                                            data-aos="fade-right"
                                                            to={`/services/${item.key}`}
                                                            className={`link-portfolio-section link-${item.key}`}
                                                        >
                                                            <FontAwesomeIcon icon={faAngleRight} />&nbsp;{t('see-more')}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Container>
                            </Col>

                        </Row>

                    );
                })
            )}
            <OurClients />
            <Row>
                <div className='init-btn '>
                    <QuestionButton
                        text={ t('lets-talk') }
                        className={"btn-home"}
                        openInNewTab={true}
                        showCotizaButton={true}
                    />
                </div>
                <Col className="p-none">
                    <Footer />
                </Col>
            </Row>
        </div>
    );
}

export default withRouter(Home);

