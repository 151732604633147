import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AOS from 'aos';
import { Container, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import Footer from '../../components/theme/Footer';

import '../../assets/css/About.css';

import 'aos/dist/aos.css';
import Button from '../content/MainButton';

AOS.init();

const About = () => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const translateAbout = useCallback(() => {
        document.title = `${t('about')} | TICONGLE Agency`;

        setIsLoading(true);
    }, [setIsLoading, t]);

    useEffect(() => {
        if (isLoading === false) {
            translateAbout();
        }
    }, [isLoading, translateAbout]);

    return (
        <div id="about" className="h-100 p-none">
            <Row className="bg-img-about h-100-vh">
                <Col className="p-none bg-shadow text-white">
                    <Container className="h-100">
                        <div className="h-100 py-5 d-flex align-items-center justify-content-start">
                            <h1 className="portfolio-title" data-aos="fade-right">
                                {t('about')}
                            </h1>
                        </div>
                    </Container>
                </Col>
            </Row>
            <Row className="bg-img-about-2 h-100-vh">
                <Col className="p-none bg-shadow text-white">
                    <Container className="h-100">
                        <div id="Home" className="d-flex align-items-start h-100 py-5 mx-3 w-100">
                            <Row className="h-100 w-100">
                                <Col xl={12} lg={12} md={12} sm={12} xs={12} className="h-100" >
                                    <div className="d-flex justify-content-start flex-wrap h-100 w-100">
                                        <div className="d-flex align-items-center flex-wrap w-100">
                                            <div className="h-100">
                                                <Row className="m-none h-100">
                                                    <Col xl={6} lg={6} md={12} sm={12} xs={12} className="p-none">
                                                        <div className="h-100">
                                                            <div className="h-100 d-flex align-items-center justify-content-start">
                                                                <div className="text-start">
                                                                    <h1 className="about-title" data-aos="fade-right"
                                                                        dangerouslySetInnerHTML={{ __html: t('about-title') }}
                                                                    >
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xl={6} lg={6} md={12} sm={12} xs={12} className="p-none">
                                                        <div className="h-100">
                                                            <div className="h-100 d-flex align-items-center justify-content-center">
                                                                <div className="text-center" data-aos="fade-up">
                                                                    <p className="description-title text-justify">
                                                                        {t('about-text')}
                                                                    </p>
                                                                        <Button
                                                                            text={"Contáctanos"}
                                                                            className={"btn-home"}
                                                                            openInNewTab={true}
                                                                        />
                                                                    
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </Col>
            </Row>
            {/* <Row className="bg-img-about-3 h-100-vh d-none">
                <Col className="p-none bg-shadow text-white">
                    <Container className="h-100">
                        <div id="Home" className="d-flex align-items-start h-100 py-5 mx-3 w-100">
                            <Row className="h-100 w-100">
                                <Col xl={ 12 } lg={ 12 } md={ 12 } sm={ 12 } xs={ 12 } className="h-100" >
                                    <div className="d-flex justify-content-start flex-wrap h-100 w-100">
                                        <div className="w-100" data-aos="fade-right">   
                                            <div 
                                                className={ `top-bar-subtitle-section bg-warning` }
                                            ></div>
                                            <h4 className="text-white text-uppercase">¿Qué hacemos?</h4>
                                        </div>
                                        <div className="d-flex align-items-center flex-wrap h-100 w-100">   
                                            <div className="h-100">
                                                <Row className="m-none h-100">
                                                    <Col xl={ 6 } lg={ 6 } md={ 12 } sm={ 12 } xs={ 12 } className="p-none">
                                                        <div className="w-100 h-100">
                                                            <div className="h-100 d-flex align-items-center justify-content-start">
                                                                <div className="text-start" >
                                                                    <h1 className="about-title" data-aos="fade-right">
                                                                        <span>Te damos</span>
                                                                        <br/>
                                                                        <span>Soluciones</span>
                                                                        <br/>
                                                                        <span>Tecnologicas</span>
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xl={ 6 } lg={ 6 } md={ 12 } sm={ 12 } xs={ 12 } className="p-none">
                                                        <div className="h-100">
                                                            <div className="h-100 d-flex align-items-center justify-content-center">
                                                                <div className="text-center">
                                                                    <p className="description-title text-justify" data-aos="fade-up">
                                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </Col>
            </Row> */}
            <Row>
                <Col className="p-none">
                    <Footer />
                </Col>
            </Row>
        </div>
    );
}

export default withRouter(About);
