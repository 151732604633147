import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function WhatsappBtn () {
    return (
        <button
            className="btn btn-success position-absolute whatsapp-button"
            onClick={() => window.open('https://wa.me/+50376029613', '_blank', 'noopener,noreferrer')}
        >
            <FontAwesomeIcon icon={faWhatsapp} />
        </button>
    );
}

export default WhatsappBtn;