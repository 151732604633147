import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const TeamCertification = () => {
    const { t } = useTranslation();

    return (
        <Row className="bg-service-portfolio">
            <Col className="p-none bg-shadow-service text-white">
                <Container className="py-5">
                    <div className="text-center mb-4" data-aos="fade-up">
                        <h2 className="fw-bold">
                            { t('team-certified') }
                        </h2>
                    </div>
                    <div id="web-plataforms">
                        <Row>
                            <Col>
                                <Card className="card-tech m-3 bg-transparent plataform h-100" data-aos="fade-up">
                                    <Card.Body className="tech-body rounded">
                                        <ul className="capabilities-list row">
                                            <li className={`border-white col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12`} data-aos="fade-up">
                                                <span className={`text-certification`}>
                                                    <FontAwesomeIcon icon={faAngleRight} />
                                                </span>
                                                &nbsp;
                                                <span className="text-white">
                                                    FullStack Developers
                                                </span>
                                            </li>
                                            <li className={`border-white col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12`} data-aos="fade-up">
                                                <span className={`text-certification`}>
                                                    <FontAwesomeIcon icon={faAngleRight} />
                                                </span>
                                                &nbsp;
                                                <span className="text-white">
                                                    Google Cloud Developers
                                                </span>
                                            </li>
                                            <li className={`border-white col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12`} data-aos="fade-up">
                                                <span className={`text-certification`}>
                                                    <FontAwesomeIcon icon={faAngleRight} />
                                                </span>
                                                &nbsp;
                                                <span className="text-white">
                                                    Scrum Fundamentals Certified
                                                </span>
                                            </li>
                                            <li className={`border-white col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12`} data-aos="fade-up">
                                                <span className={`text-certification`}>
                                                    <FontAwesomeIcon icon={faAngleRight} />
                                                </span>
                                                &nbsp;
                                                <span className="text-white">
                                                    Agile Methodologies
                                                </span>
                                            </li>
                                            <li className={`border-white col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12`} data-aos="fade-up">
                                                <span className={`text-certification`}>
                                                    <FontAwesomeIcon icon={faAngleRight} />
                                                </span>
                                                &nbsp;
                                                <span className="text-white">
                                                    Azure Cloud Skills Challenge
                                                </span>
                                            </li>
                                            <li className={`border-white col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12`} data-aos="fade-up">
                                                <span className={`text-certification`}>
                                                    <FontAwesomeIcon icon={faAngleRight} />
                                                </span>
                                                &nbsp;
                                                <span className="text-white">
                                                    Digital Marketing Project Management
                                                </span>
                                            </li>
                                            <li className={`border-white col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12`} data-aos="fade-up">
                                                <span className={`text-certification`}>
                                                    <FontAwesomeIcon icon={faAngleRight} />
                                                </span>
                                                &nbsp;
                                                <span className="text-white">
                                                    Instructional Design
                                                </span>
                                            </li>
                                            <li className={`border-white col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12`} data-aos="fade-up">
                                                <span className={`text-certification`}>
                                                    <FontAwesomeIcon icon={faAngleRight} />
                                                </span>
                                                &nbsp;
                                                <span className="text-white">
                                                    Marketing Automation
                                                </span>
                                            </li>
                                        </ul>
                                    </Card.Body>
                                </Card>
                            </Col>

                        </Row>
                    </div>
                </Container>
            </Col>
        </Row>
    );
};

export default TeamCertification;
