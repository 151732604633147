import { useTranslation } from 'react-i18next';

import Button from '../../components/content/MainButton';

const QuestionService = () => {
    const { t } = useTranslation();

    return (
        <div className='question-service '>
            <p className='text-center question-text'>
                { t('question-boot-business') }
            </p>
            <Button
                text={ t('lets-talk') }
                className={"btn-service"}
                openInNewTab={true}
                icon={false}
            />
        </div>
    );
}

export default QuestionService;