import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';

import Footer from '../theme/Footer';

import svgBroke from '../../assets/svg/broke.svg';

import '../../assets/css/404NotFound.css';

const NotFound = () => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const translateNotFound = useCallback(() => {          
        document.title = `${t('page-not-found')} | TICONGLE Agency`;

        setIsLoading(true);
    }, [setIsLoading, t]);

    useEffect(() => {
        if (isLoading === false) {
            translateNotFound();
        }
    }, [isLoading, translateNotFound]);

    return (
        <div id="NotFound" className="h-100 p-none">
            <div className="h-100 p-none">
                <Row className="h-100-vh bg-purple-dark-ticongle">
                    <Col>
                        <Container className="h-100">
                            <div className="h-100 d-flex justify-content-center align-items-center flex-column">
                                <div className="w-100 py-2">
                                    <h1 className="title-not-found text-center">404 - { t('page-not-found') }</h1>
                                </div>
                                <div className="w-100 py-2">
                                    <h5 className="text-white text-center">{ t('not-found-text') }</h5>
                                </div>
                                <div>
                                    <img 
                                        src={ svgBroke }
                                        className="icon-404-size"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </Container>
                    </Col>
                </Row>
                <Row>
                    <Col className="p-none">
                        <Footer />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default NotFound;
