import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

function MainButton({ text, link, className, openInNewTab, icon = true, url = null }) {
    const target = openInNewTab ? "_blank" : "_self";
    return (
        <div className='btn-main'>
            <a
                href={ url ? url : "https://wa.me/+50376029613" }
                target={target}
                rel={openInNewTab ? "noopener noreferrer" : ""}
                className={`${className}`}
            >
                {icon && <><FontAwesomeIcon icon={faAngleRight} className='icon'/>
                &nbsp;</>}
                {text}
            </a>
        </div>
    );
}

export default MainButton;