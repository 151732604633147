
const ImagePortfolio = ({ data, service }) => {
    let img = require(`../../assets/images/portfolio/${service}/${data.image}`).default;

    return (
        <a 
            href={ data.url } 
            className={ `item-container border-color-${service}` } 
            target="_blank" 
            rel="noopener noreferrer"
        >
            <img 
                src={ img }
                className="w-100 h-100 img-project-portfolio"
                alt={ data.name }
            />
        </a>
    );
}

export default ImagePortfolio;
